import React from 'react';
import '../../style/StatusBar.css';
import { getDateFromPickerString, dateToEuropeanFormat, timeToDatePickerFormat } from '../libs-common/DateTimeHelper';
import MainButton from '../libs-common/MainButton';
import enumAdminType from '../model/enums/adminType';
import enumRequestState from '../model/enums/request_state';
var createReactClass = require('create-react-class');

var AdvancedSearch = createReactClass({
    getInitialState: function () {
        let retObject = {
            pendingForClient: this.props.pendingForClient,
            pendingForDelivery: this.props.pendingForDelivery,
            inProgress: false,
            done: false,
            canceled: false,
            selectedDate: this.props.selectedDate,
            selectedSort: "unseenOnTop",
            decreasing: true,
            unreadCount: this.props.unreadCount,
            disabled: this.props.disabled,
            searchCriteria: [
                { "default": { value: "" } }
            ],
        };
        // TODO: Add more sort values for delivery time, etc...
        retObject.sortValues = [
            { value: "unseenOnTop", name: "Neotvorene" },
            { value: "arrivedTime", name: "Primljeno vreme" },
            { value: "state", name: "Stanje dostave" },
        ];

        if (this.props.user.type === enumAdminType.CLIENT) {
            retObject.pendingForClientName = "Dospeli zahtevi";
            retObject.pendingForDeliveryName = "Čeka se dostava";
            retObject.showUnreadClient = true;
            retObject.showUnreadOther = false;
        }
        else {
            retObject.pendingForClientName = "Dospelo klijentu";
            retObject.pendingForDeliveryName = "Dospelo dostava";
            retObject.showUnreadClient = false;
            retObject.showUnreadOther = true;
        }
        return retObject;
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.unreadCount !== prevProps.unreadCount) {
            this.setState({ unreadCount: this.props.unreadCount });
        }
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({ disabled: this.props.disabled });
        }
    },
    onSelectionChange: function (state) {
        if (this.state.disabled) {
            return;
        }
        let retObj = {
            pendingForClient: this.state.pendingForClient,
            pendingForDelivery: this.state.pendingForDelivery,
            inProgress: this.state.inProgress,
            done: this.state.done,
            canceled: this.state.canceled,
            selectedDate: this.state.selectedDate,
        };
        switch (state) {
            case enumRequestState.PENDING_FOR_CLIENT:
                retObj.pendingForClient = !retObj.pendingForClient
                break;
            case enumRequestState.PENDING_FOR_DELIVERY:
                retObj.pendingForDelivery = !retObj.pendingForDelivery
                break;
            case enumRequestState.IN_PROGRESS:
                retObj.inProgress = !retObj.inProgress
                break;
            case enumRequestState.DONE:
                retObj.done = !retObj.done
                break;
            case enumRequestState.CANCELED:
                retObj.canceled = !retObj.canceled
                break;
            default:
                break;
        }
        this.setState(retObj, () => {
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(retObj);
            }
            else {
                console.log("On selection change function is not set!");
            }
        });
    },
    onDatePickerChange: function (event) {
        const retObj = { selectedDate: getDateFromPickerString(event.target.value) };
        this.setState(retObj);
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    onSortSelectionChange: function (event) {
        const retObj = { selectedSort: (event.target.value + (this.state.decreasing ? "I" : "D")) }
        this.setState({ selectedSort: event.target.value });
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    onCheckboxChange: function (event) {
        const retObj = { selectedSort: (this.state.selectedSort + (event.target.checked ? "I" : "D")) }
        if (event.target.id === "rotate") {
            this.setState({ decreasing: event.target.checked });
        }
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(retObj);
        }
        else {
            console.log("On selection change function is not set!");
        }
    },
    render: function () {
        return (
            <>
                <input type="checkbox" name="" id="status-open" />
                <label htmlFor="status-open" className="open-status-bar">
                    <img src="./images/status-bar/right-arrow.svg" alt="" />
                    {this.state.unreadCount > 0 ?
                        <div className="label-order-count">
                            <p>{this.state.unreadCount}</p>
                        </div> : null}
                </label>
                <div className="status-bar-container">
                    <div className="status-bar-content">
                        <MainButton
                            classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
                            content="<- Povratak nazad"
                            onClick={this.props.advancedSearchChange}
                        />

                        {searchItem({ type: "default", value: [{ value: "val", name: "name" }] })}
                        <MainButton
                            classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
                            content="Pretraži"
                            onClick={this.props.advancedSearchChange}
                        />
                    </div>
                </div>
            </>
        )
    }
});

export default AdvancedSearch;

const searchItem = (item, onChange, onRemove, disabled, selectedDate) => {
    switch (item.type) {
        case "ordersFrom":
            return (
                <div className={("date-picker " + (disabled ? "disabled-status-bar" : ""))}>
                    <label htmlFor="select-day">Porudžbine od:</label>
                    <form action="" className="date-pick-form">
                        <label htmlFor="select-day" id="date-label">{dateToEuropeanFormat(selectedDate)}</label>
                        < input type="date" name="select-day" id="select-day" min="2021-01-01"
                            disabled={disabled}
                            value={timeToDatePickerFormat(selectedDate)}
                            onChange={onChange} />
                    </form>
                </div>);
        case "ordersTo":
            return (
                <div className={("date-picker " + (disabled ? "disabled-status-bar" : ""))}>
                    <label htmlFor="select-day">Porudžbine do:</label>
                    <form action="" className="date-pick-form">
                        <label htmlFor="select-day" id="date-label">{dateToEuropeanFormat(selectedDate)}</label>
                        < input type="date" name="select-day" id="select-day" min="2021-01-01"
                            disabled={disabled}
                            value={timeToDatePickerFormat(selectedDate)}
                            onChange={onChange} />
                    </form>
                </div>);
        case "createdBy":

            return <></>;

        default:
            return (
                <div className={("sort-orders " + (disabled ? "disabled-status-bar" : ""))}>
                    <label htmlFor="sort">Dodaj kriteriju:</label>
                    <div className="form-container">
                        <form action="" className="form-sort">
                            <select name="sort" id="sort" onChange={onChange} disabled={disabled}>
                                {item.value.map((sortValueObj) => {
                                    return (
                                        <option key={sortValueObj.value} value={sortValueObj.value}
                                            className="sort-option">{sortValueObj.name}</option>
                                    );
                                })}
                            </select>
                        </form>
                        {/* <button
                            onClick={onRemove}
                        > x </button> */}
                    </div>
                </div>);
    }
    // return (
    //     <>
    //         <input type="checkbox" name="" id="status-open" />
    //         <label htmlFor="status-open" className="open-status-bar">
    //             <img src="./images/status-bar/right-arrow.svg" alt="" />
    //             {this.state.unreadCount > 0 ?
    //                 <div className="label-order-count">
    //                     <p>{this.state.unreadCount}</p>
    //                 </div> : null}
    //         </label>
    //         <div className="status-bar-container">
    //             <div className="status-bar-content">
    //                 <MainButton
    //                     classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
    //                     content="<- Povratak nazad"
    //                     onClick={this.props.advancedSearchChange}
    //                 />
    //                 <MainButton
    //                     classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
    //                     content="+"
    //                     onClick={this.props.advancedSearchChange}
    //                 />
    //                 <div className={("single-status-bar " +
    //                     (this.state.disabled ? "disabled-status-bar" : (this.state.canceled ? "selected-status-bar" : "")))}
    //                     onClick={this.onSelectionChange.bind(this, enumRequestState.CANCELED)}>
    //                     <img src="./images/status-bar/cancel.svg" alt="" className="image-yellow" />
    //                     <img src="./images/status-bar/cancel (1).svg" alt="" className="image-black" />
    //                     <p>Otkazani zahtevi</p>
    //                     <button
    //                         onClick={this.props.advancedSearchChange}
    //                     > x </button>
    //                 </div>

    //                 <div className={("sort-orders " + (this.state.disabled ? "disabled-status-bar" : ""))}>
    //                     <label htmlFor="sort">Sortiraj:</label>
    //                     <div className="form-container">
    //                         <form action="" className="form-sort">
    //                             <select name="sort" id="sort" onChange={this.onSortSelectionChange} disabled={this.state.disabled}>
    //                                 {this.state.sortValues.map((sortValueObj) => {
    //                                     return (
    //                                         <option key={sortValueObj.value} value={sortValueObj.value}
    //                                             className="sort-option">{sortValueObj.name}</option>
    //                                     );
    //                                 })}
    //                             </select>
    //                         </form>
    //                         {this.state.disabled ? null :
    //                             <>
    //                                 <input type="checkbox" id="rotate" checked={this.state.decreasing} onChange={this.onCheckboxChange} />
    //                                 <label htmlFor="rotate" className="rotate-label"><i><img src="/images/status-bar/up-arrow.svg" alt="" className="clicked" /></i></label>
    //                             </>}
    //                         <button
    //                             onClick={this.props.advancedSearchChange}
    //                         > x </button>
    //                     </div>
    //                 </div>
    //                 <MainButton
    //                     classname={("new-delivery " + (this.state.disabled ? "disabled-status-bar" : ""))}
    //                     content="Pretraži"
    //                     onClick={this.props.advancedSearchChange}
    //                 />
    //             </div>
    //         </div>
    //     </>
    // )
}