/* eslint-disable react/no-direct-mutation-state */
import "../../style/DeliveryModal.css";
import Order from '../model/order';
import enumRequestState from '../model/enums/request_state';
import ModalBodySender from "./DeliveryModalSender";
import ModalBodyReceiver from "./DeliveryModalReceiver";
import ModalBodyComment from "./DeliveryModalComment";
import ModalBodySubmit from "./DeliveryModalSubmit";
import enumAdminType from "../model/enums/adminType";
var createReactClass = require('create-react-class');

var DeliveryModal = createReactClass({
    getInitialState: function () {
        let _order = new Order();
        _order.state = enumRequestState.PENDING_FOR_DELIVERY;
        if (this.props.user.type === enumAdminType.CLIENT && this.props.client) {
            if (this.props.client.locations[0]) {
                _order.senderAddress = this.props.client.locations[0];
                _order.senderAddress.name = this.props.client.name;
                _order.deliveryId = this.props.client.deliveryId;
                _order.cart.client = this.props.client;
            }
            _order.cart.client = this.props.client;
        }
        else {
            _order.deliveryId = this.props.user.deliveryIds[0];
        }
        return {
            selectedContent: "receiver",
            order: _order,
            waiting_for_server_respond: true,
            send_success: true,
            error_message: "",
            dbHandler: this.props.dbHandler,
            captchaEnabled: true,
            onlyReceiver: this.props.onlyReceiver || this.props.user.type === enumAdminType.CLIENT,
            wrongAddress: false,
            wrongName: false,
            wrongPhone: false,
            user: this.props.user,
        }
    },
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    },
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    },
    handleClose: function () {
        this.props.onClose();
    },
    onUpdateOrderDbFinish: function (success) {
        if (!success) {
            this.setState({
                waiting_for_server_respond: false,
                send_success: false,
                error_message: ""
            });
        }
        else {
            this.setState({
                waiting_for_server_respond: false,
                send_success: true,
                error_message: ""
            });
        }
    },
    onSkipSenderChange: function (value) {
        if (value) {
            this.state.order.generateUndefinedSender();
        }
        else {
            this.state.order.clearUndefinedSender();
        }
        this.setState({ onlyReceiver: value });
    },
    changeSelectedContent: function (go_forward) {
        if (go_forward) {
            switch (this.state.selectedContent) {
                case "receiver":
                    if (this.validateInputValues()) {
                        if (this.state.onlyReceiver) {
                            this.setState({ selectedContent: "comment" });
                        }
                        else {
                            this.setState({ selectedContent: "sender" });
                        }
                    }
                    break;
                case "sender":
                    if (this.validateInputValues()) {
                        this.setState({ selectedContent: "comment" });
                    }
                    break;
                case "comment":
                    this.setState({ selectedContent: "submit" });
                    this.state.order.orderId = this.state.order.Id;
                    this.state.order.receiverAddressId = this.state.order.receiverAddress.generateAddressId();
                    this.state.order.senderAddressId = this.state.order.senderAddress.generateAddressId();
                    console.log(this.state.order.toJason());
                    this.state.dbHandler.addOrderDB(this.state.order, this.onUpdateOrderDbFinish);
                    break;
                case "oneClickDelivery":
                    this.setState({ selectedContent: "submit" });
                    this.state.order.generateUndefinedReceiver();
                    this.state.order.orderId = this.state.order.Id;
                    const clientId = this.state.user.storeIds[0];
                    this.state.dbHandler.addOrderDB(this.state.order, this.onUpdateOrderDbFinish, clientId);
                    break;
                case "submit":
                    this.handleClose(true);
                    break;
                default:
                    break;
            }
        }
        else {
            switch (this.state.selectedContent) {
                case "receiver":
                    this.handleClose(false);
                    break;
                case "sender":
                    this.setState({ selectedContent: "receiver" });
                    break;
                case "comment":
                    if (this.state.onlyReceiver) {
                        this.setState({ selectedContent: "receiver" });
                    }
                    else {
                        this.setState({ selectedContent: "sender" });
                    }
                    break;
                case "oneClickDelivery":
                    this.handleClose(false);
                    break;
                default:
                    break;
            }
        }
    },
    validateInputValues: function () {
        const address = (this.state.selectedContent === "sender" ? this.state.order.senderAddress : this.state.order.receiverAddress);
        let _wrongAddress = false;
        let _wrongName = false;
        let _wrongPhone = false;

        if (address.address) {
            if (address.address.length > 40) {
                _wrongAddress = true;
            }
        }
        else {
            _wrongAddress = true;
        }

        if (address.name) {
            if (address.name.length > 40) {
                _wrongName = true;
            }
        }
        else {
            _wrongName = true;
        }

        if (address.phone) {
            if (address.name.length > 18) {
                _wrongPhone = true;
            }
            if (!address.phone.match(/^[0-9\-+]{5,18}$/)) {
                _wrongPhone = true;
            }
        }
        else {
            _wrongPhone = true;
        }

        this.setState({
            wrongAddress: _wrongAddress,
            wrongName: _wrongName,
            wrongPhone: _wrongPhone,
        });

        return (!_wrongAddress && !_wrongName && !_wrongPhone);
    },
    getLeftButtonText: function () {
        switch (this.state.selectedContent) {
            case "receiver":
            case "oneClickDelivery":
                return ("Otkaži");
            case "sender":
            case "comment":
                return ("Prethodno");
            default:
                return ("Ok");
        }
    },
    getRightButtonText: function () {
        switch (this.state.selectedContent) {
            case "receiver":
            case "sender":
                return ("Sledeće");
            case "comment":
            case "oneClickDelivery":
                return ("Zakaži");
            default:
                return ("Ok");
        }
    },
    render: function () {
        let content = null;
        switch (this.state.selectedContent) {
            case "receiver":
                content = <ModalBodyReceiver
                    Order={this.state.order}
                    addresses={this.state.addresses}
                    loadingAddresses={this.state.loadingAddresses}
                    showAddresses={this.state.showAddresses}
                    wrongAddress={this.state.wrongAddress}
                    wrongName={this.state.wrongName}
                    wrongPhone={this.state.wrongPhone}
                    onSkipSenderChange={this.onSkipSenderChange}
                    onlyReceiver={this.state.onlyReceiver}
                    hideOnlyReceiverCB={this.props.user.type === enumAdminType.CLIENT} />;
                break;
            case "sender":
                content = <ModalBodySender Order={this.state.order}
                    wrongAddress={this.state.wrongAddress}
                    wrongName={this.state.wrongName}
                    wrongPhone={this.state.wrongPhone} />;
                break;
            case "comment":
                content = <>
                    <ModalBodyComment Order={this.state.order} onReCaptchaChange={this.onReCaptchaChange} />
                </>;
                break;
            case "oneClickDelivery":
                content = <>
                    <div className="modal-body-form">
                        <div className="modal-body-comment">
                            <div className="confirm-new-delivery">
                                <h2>Molim Vas da klikom na dugme "Zakaži" potvrdite da želite da dostavljač dodje u Vaš lokal</h2>
                            </div>
                        </div>
                    </div>
                </>;
                break;
            case "submit":
                content = <>
                    <ModalBodySubmit
                        waitingForRespond={this.state.waiting_for_server_respond}
                        success={this.state.send_success}
                        errorMessage={this.state.error_message} />
                </>;
                break;
            default:
                break;
        }
        return (
            <div className="delivery-modal-container">
                <div className="dialog-modal">
                    <div className="dialog-header">
                        <h2>Zakaži dostavu</h2>
                        {this.state.selectedContent === "submit" ? null :
                            <span className="close-modal" onClick={() => { this.handleClose(false) }}>
                                <h1>&times;</h1>
                            </span>}
                    </div>
                    <div className="dialog-content">
                        {content}
                    </div>
                    <div className="dialog-footer">
                        {this.state.selectedContent !== "submit" ?
                            <button onClick={this.changeSelectedContent.bind(this, false)} className="send-request">
                                {this.getLeftButtonText()}
                            </button> : null}
                        <button onClick={this.changeSelectedContent.bind(this, true)}
                            className={("send-request " + (((this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) ||
                                (this.state.selectedContent === "comment" && !this.state.captchaEnabled)) ? "disabled" : ""))}
                            disabled={(this.state.selectedContent === "submit" && this.state.waiting_for_server_respond) ||
                                (this.state.selectedContent === "comment" && !this.state.captchaEnabled)}>
                            {this.getRightButtonText()}
                        </button>
                    </div>
                </div>
            </div>

        )
    }
})

export default DeliveryModal
