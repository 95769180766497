import MainButton from '../libs-common/MainButton';

var createReactClass = require('create-react-class');

var SingleOrderCancellationModal = createReactClass({
    getInitialState: function () {
        return {
            cancellationReason: "",
        };
    },
    render: function () {
        return (
            <>
                <div className="message-box-container">
                    <div className="message-box-content flex-column border-radius">
                        <div className="message-box-header">
                            <p>Otkaži zahtev</p>
                        </div>
                        <div className="message-box-body">
                            <p>
                                Info: Ovaj komentar će biti prikazan korisniku. <br />
                                Savet je da se na fin način objasni zašto zahtev ne može biti ispunjen.
                            </p>
                            <textarea name="" id="" cols="" rows="" className="textarea-cancel-order"
                                placeholder="Navedite razlog otkazivanja..."
                                value={this.state.cancellationReason}
                                onChange={(event) => {
                                    this.setState({ cancellationReason: event.target.value });
                                    this.props.order.cancellationReason = event.target.value;
                                }}>
                            </textarea>
                        </div>
                        <div className="message-box-footer">
                            <MainButton
                                classname="modal-button"
                                content="Nazad"
                                id="backCancel"
                                onClick={() => { this.props.onClick("backCancel") }}
                            />
                            <MainButton
                                classname="modal-button red-button"
                                content="Otkaži"
                                id="confirmCancel"
                                onClick={() => { this.props.onClick("confirmCancel"); }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
});

export default SingleOrderCancellationModal;




