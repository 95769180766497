/* eslint-disable import/no-named-as-default */
/* eslint-disable require-jsdoc */
import enumAdminType from "./enums/adminType";

/**
 * Placeholder class for Order attributes.
 */
export class Admin {
  id: string;
  name: string;
  type: enumAdminType;
  email: string;
  verified: boolean;
  storeIds: Array<string>;
  deliveryIds: Array<string>;
  settings: {};

  constructor() {
    this.id = "";
    this.name = "";
    this.type = enumAdminType.UNIDENTIFIED;
    this.email = "";
    this.verified = false;
    this.storeIds = [];
    this.deliveryIds = [];
    this.settings = {};
  }

  loadFromJson(jsonObject: any) {
    if (jsonObject) {
      if (jsonObject.id) {
        this.id = jsonObject.id;
      }
      if (jsonObject.name) {
        this.name = jsonObject.name;
      }
      if (jsonObject.type) {
        this.type = jsonObject.type;
      }
      if (jsonObject.email) {
        this.email = jsonObject.email;
      }
      if (jsonObject.verified) {
        this.verified = jsonObject.verified;
      }
      if (jsonObject.storeIds) {
        this.storeIds = jsonObject.storeIds;
      }
      if (jsonObject.deliveryIds) {
        this.deliveryIds = jsonObject.deliveryIds;
      }
      if (jsonObject.settings) {
        this.settings = jsonObject.settings;
      }
    }
  }

  toJason(): any {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      email: this.email,
      verified: this.verified,
      storeIds: this.storeIds,
      deliveryIds: this.deliveryIds,
      settings: this.settings,
    };
  }
}

export default Admin;
