import enumRequestState from "../model/enums/request_state";
import enumAdminType from "../model/enums/adminType";

let intervalId = 0;

export function changeTabTitle(unreadObj, user) {
    let num = user.type === enumAdminType.CLIENT ?
        unreadObj[enumRequestState.PENDING_FOR_CLIENT] :
        unreadObj[enumRequestState.PENDING_FOR_DELIVERY];
    if (num) {
        const withNumber = "(" + num + ") deMango | Internal portal";
        let w = true;
        clearInterval(intervalId);
        intervalId = setInterval(() => {
            w = !w;
            w ? document.title = "New orders!" : document.title = withNumber;
        }, 400);
    }
    else {
        clearInterval(intervalId);
        document.title = "deMango | Internal portal";
        // document.favicon = "%PUBLIC_URL%/favicon.ico";
    }
}