/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import '../../style/PortalPage.css';
import enumAdminType from '../model/enums/adminType';
import enumRequestState from '../model/enums/request_state';
import DisplayCard from './DisplayCard';
import Loader from '../libs-common/Loader';
import SingleOrderModal from './SingleOrderModal';
import SingleOrderCart from './SingleOrderCart';
var createReactClass = require('create-react-class');

var PortalPage = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.getOrdersByStatesDB(this.getSelectedOrderStates(), this.props.selectedOrderStatus.selectedDate, this.onOrdersLoadFinish);
        return {
            user: this.props.user,
            orders: [],
            pendingForClient: this.props.selectedOrderStatus.pendingForClient,
            pendingForDelivery: this.props.selectedOrderStatus.pendingForDelivery,
            inProgress: this.props.selectedOrderStatus.inProgress,
            done: this.props.selectedOrderStatus.done,
            canceled: this.props.selectedOrderStatus.canceled,
            selectedDate: this.props.selectedOrderStatus.selectedDate,
            selectedSort: this.props.selectedOrderStatus.selectedSort,
            loading: true,
            selectedOrder: null,
            showOrderModal: false,
            sortingInProgress: false,
            ordersFromListener: this.props.ordersFromListener,
        };
    },
    // TODO: Make detection if db is already loading so it will not do fake load!
    componentDidUpdate: function (prevProps) {
        let loadFromDb = false;
        let makeFakeLoad = false; // in case when button is disabled, just hide orders.  
        if (this.props.selectedOrderStatus.pendingForClient !== prevProps.selectedOrderStatus.pendingForClient) {
            if (!this.props.selectedOrderStatus.pendingForClient) {
                makeFakeLoad = true;
            }
            loadFromDb = true;
        }
        if (this.props.selectedOrderStatus.pendingForDelivery !== prevProps.selectedOrderStatus.pendingForDelivery) {
            if (!this.props.selectedOrderStatus.pendingForDelivery) {
                makeFakeLoad = true;
            }
            loadFromDb = true;
        }
        if (this.props.selectedOrderStatus.inProgress !== prevProps.selectedOrderStatus.inProgress) {
            if (!this.props.selectedOrderStatus.inProgress) {
                makeFakeLoad = true;
            }
            loadFromDb = true;
        }
        if (this.props.selectedOrderStatus.done !== prevProps.selectedOrderStatus.done) {
            if (!this.props.selectedOrderStatus.done) {
                makeFakeLoad = false;
            }
            loadFromDb = true;
        }
        if (this.props.selectedOrderStatus.canceled !== prevProps.selectedOrderStatus.canceled) {
            if (!this.props.selectedOrderStatus.canceled) {
                makeFakeLoad = false;
            }
            loadFromDb = true;
        }
        if (this.props.selectedOrderStatus.selectedDate !== prevProps.selectedOrderStatus.selectedDate) {
            if (this.props.selectedOrderStatus.done || this.props.selectedOrderStatus.canceled) {
                loadFromDb = true;
            }
        }
        if (this.props.selectedOrderStatus.selectedSort !== prevProps.selectedOrderStatus.selectedSort) {
            this.setState({
                selectedSort: this.props.selectedOrderStatus.selectedSort,
                sortingInProgress: true
            }, () => {
                const combinedOrders = this.combineOrders(this.state.orders, this.state.ordersFromListener);
                this.sortOrders(this.props.selectedOrderStatus.selectedSort, combinedOrders, this.onOrdersSortFinish, true);
            });
        }
        if (this.props.ordersFromListener !== prevProps.ordersFromListener) {
            const orders = this.combineOrders(this.state.orders, this.props.ordersFromListener);
            this.setState({
                ordersFromListener: this.props.ordersFromListener,
            }, () => {
                if (!this.state.loading) {
                    this.setState({
                        loading: true,
                        sortingInProgress: true,
                    }, () => {
                        this.makeFakeDBLoad(orders, this.getSelectedOrderStates(), this.onOrdersLoadFinish);
                    })
                }
            });
        }
        if (loadFromDb) {
            if (makeFakeLoad) {
                this.setState(this.props.selectedOrderStatus, () => {
                    this.setState({ loading: true, sortingInProgress: true }, () => {
                        this.makeFakeDBLoad(this.state.orders, this.getSelectedOrderStates(), this.onOrdersLoadFinish)
                    });
                });
            }
            else {
                this.setState(this.props.selectedOrderStatus, () => {
                    this.setState({ loading: true, sortingInProgress: true }, () => {
                        if (this.props.enableDisableSideMenu) {
                            this.props.enableDisableSideMenu(true);
                        }
                        this.props.dbHandler.getOrdersByStatesDB(this.getSelectedOrderStates(), this.props.selectedOrderStatus.selectedDate, this.onOrdersLoadFinish)
                    });

                });
            }
        }
    },
    getSelectedOrderStates: function () {
        const orderStates = [];
        if (this.props.selectedOrderStatus.pendingForClient) {
            orderStates.push(enumRequestState.PENDING_FOR_CLIENT);
        }
        if (this.props.selectedOrderStatus.pendingForDelivery) {
            orderStates.push(enumRequestState.PENDING_FOR_DELIVERY);
        }
        if (this.props.selectedOrderStatus.inProgress) {
            orderStates.push(enumRequestState.IN_PROGRESS);
        }
        if (this.props.selectedOrderStatus.done) {
            orderStates.push(enumRequestState.DONE);
        }
        if (this.props.selectedOrderStatus.canceled) {
            orderStates.push(enumRequestState.CANCELED);
        }
        return orderStates;
    },
    makeFakeDBLoad: function (orders, selectedOrderStates, onLoadFinish) {
        if (this.props.enableDisableSideMenu) {
            this.props.enableDisableSideMenu(true)
        }
        let newOrders = [];
        orders.forEach(order => {
            if (selectedOrderStates.includes(order.state)) {
                newOrders.push(order);
            }
        });
        if (onLoadFinish) {
            onLoadFinish(newOrders);
        }
    },
    onOrdersLoadFinish: function (orders) {
        const combinedOrders = this.combineOrders(orders, this.state.ordersFromListener);
        this.sortOrders(this.state.selectedSort, combinedOrders, this.onOrdersSortFinish, false);
        if (this.props.enableDisableSideMenu) {
            this.props.enableDisableSideMenu(false);
        }
    },
    onOrdersSortFinish: function (orders) {
        this.setState({
            orders: orders,
            loading: false,
            sortingInProgress: false,
        });
    },
    combineOrders: function (orders, ordersFromListener) {
        const selectedStates = this.getSelectedOrderStates();
        const ordersMap = {};
        const newOrdersArray = [];
        orders.forEach(order => {
            ordersMap[order.orderId] = order;
        });
        ordersFromListener.forEach(orderFromListener => {
            ordersMap[orderFromListener.orderId] = orderFromListener;
            if (this.state.selectedOrder && this.state.selectedOrder.orderId === orderFromListener.orderId) {
                this.setState({ selectedOrder: orderFromListener });
            }
        });
        Object.values(ordersMap).forEach(orderToAdd => {
            if (selectedStates.includes(orderToAdd.state)) {
                newOrdersArray.push(orderToAdd);
            }
        });

        return newOrdersArray;
    },
    sleep: async function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    sortOrders: async function (sortCriteria, orders, onSortFinish, doSleep = true) {
        let retArray = [];
        switch (sortCriteria) {
            case "arrivedTimeD":
                retArray = [].concat(orders)
                    .sort((a, b) => a.dateOfCreation > b.dateOfCreation ? 1 : -1);
                break;
            case "arrivedTimeI":
                retArray = [].concat(orders)
                    .sort((a, b) => a.dateOfCreation < b.dateOfCreation ? 1 : -1);
                break;
            case "stateD":
                retArray = [].concat(orders)
                    .sort((a, b) => a.state > b.state ? 1 : -1);
                break;
            case "stateI":
                retArray = [].concat(orders)
                    .sort((a, b) => a.state < b.state ? 1 : -1);
                break;
            case "unseenOnTopI":
            case "unseenOnTopD":
                retArray = [].concat(orders)
                    .sort((a, b) => (a.seen > b.seen || a.dateOfCreation < b.dateOfCreation ? 1 : -1));
                break;
            default:
                retArray = [].concat(orders)
                    .sort((a, b) => a.dateOfCreation > b.dateOfCreation ? 1 : -1);
                break;
        }

        if (doSleep) {
            await this.sleep(250);
        }
        if (onSortFinish) {
            onSortFinish(retArray);
        }
        return retArray;
    },
    onOrderCardClick: function (order) {
        if (!order.updating) {
            if (!order.seen) {
                this.props.dbHandler.clearOrderEvent(order, null);
            }
            this.setState({
                selectedOrder: order,
                showOrderModal: true,
            });
        }
    },
    onOrderModalClose: function (updateOrder = false) {
        if (updateOrder) {
            this.state.selectedOrder.updating = true;
        }
        this.setState({
            selectedOrder: null,
            showOrderModal: false,
        });
        this.makeFakeDBLoad(this.state.orders, this.getSelectedOrderStates(), this.onOrdersLoadFinish);
    },
    onOrderUpdateFinish: function (orderDb) {
        if (orderDb) {
            this.state.orders.forEach(order => {
                if (order.orderId === orderDb.orderId) {
                    order.loadFromJson(orderDb.toJason());
                    order.updating = false;
                }
            });
        }
        this.makeFakeDBLoad(this.state.orders, this.getSelectedOrderStates(), this.onOrdersLoadFinish);
    },
    render: function () {
        return (
            <div className="portal-page-body">
                <div className="portal-page-body-content">
                    {(this.state.showOrderModal && this.state.selectedOrder) ?
                        <>
                            {this.state.user.type === enumAdminType.CLIENT ?
                                <>
                                    <SingleOrderCart
                                        order={this.state.selectedOrder}
                                        onClose={this.onOrderModalClose}
                                        dbHandler={this.props.dbHandler}
                                        onOrderUpdateFinish={this.onOrderUpdateFinish}
                                        user={this.state.user} />
                                </> :
                                <>
                                    <SingleOrderModal
                                        order={this.state.selectedOrder}
                                        onClose={this.onOrderModalClose}
                                        dbHandler={this.props.dbHandler}
                                        onOrderUpdateFinish={this.onOrderUpdateFinish}
                                        user={this.state.user} />
                                </>}
                        </> : null}
                    {(this.state.loading || this.state.sortingInProgress) ?
                        <>
                            {Loader()}
                        </> :
                        <> {(this.state.orders && this.state.orders.length > 0) ?
                            <>
                                {this.state.orders.map((order) => {
                                    return (
                                        <DisplayCard
                                            order={order}
                                            onClick={this.onOrderCardClick}
                                            user={this.state.user}
                                            deliveries={this.props.deliveries}
                                        />
                                    );
                                })}
                            </> :
                            <>
                                {/* NO DATA */}
                            </>}
                        </>}
                </div>
            </div>
        )
    }
});

export default PortalPage
