/* eslint-disable react/no-direct-mutation-state */
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/SingleOrderModal.css';
import MainButton from '../libs-common/MainButton';
// import enumAdminType from '../model/enums/adminType';
import enumRequestState from '../model/enums/request_state';
import Order from '../model/order';
import DebugObjectModal from './DebugObjectModal';
import SingleOrderCancellationModal from './SingleOrderCancellationModal';
const createReactClass = require('create-react-class');

const SingleOrderCart = createReactClass({
    getInitialState: function () {
        //Copy object to the new one in case if user doesn't want to save changes.
        let _order = new Order();
        _order.loadFromJson(this.props.order.toJason());
        const _client = _order.cart.client;
        return {
            order: _order,
            showOrderCancellationComment: false,
            client: _client,
            deliveryStartTime: _client.defaultOrderTime ? _client.defaultOrderTime : 5,
            timeIsValid: true,
            orderWasChanged: false,
        };
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.order.state !== this.state.order.state && !this.state.orderWasChanged) {
            this.setState({
                orderWasChanged: true,
                showOrderCancellationComment: false,
            });
        }
    },
    componentDidMount() {
        document.body.style.overflow = 'hidden';
    },
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    },
    onInputChange: function (event) {
        switch (event.target.id) {
            case "approx-delivery-time":
                const val = event.target.value;
                this.setState({ deliveryStartTime: val })
                break;

            default:
                break;
        }
    },
    validateInputTime: function () {
        return this.state.deliveryStartTime > 0;
    },
    onButtonClick: function (id) {
        console.log();
        switch (id) {
            case "cancelOrder":
                if (!this.state.orderWasChanged) {
                    this.setState({ showOrderCancellationComment: true });
                }
                break;
            case "backCancel":
                this.setState({ showOrderCancellationComment: false });
                break;
            case "confirmCancel":
                if (this.state.order.cancellationReason.length > 5 && !this.state.orderWasChanged) {
                    // TODO: Add on db finish function
                    this.props.dbHandler.updateOrderDB(this.state.order, false, true, this.props.onOrderUpdateFinish);
                    this.props.onClose(true);
                }
                else if (this.state.orderWasChanged) {
                    this.setState({ showOrderCancellationComment: false });
                }
                else {
                    console.log("Cancellation message is mandatory.");
                }
                break;
            case "confirmOrder":
                this.state.order.estimatedMakingTime = this.state.deliveryStartTime;
                if (this.validateInputTime() && !this.state.orderWasChanged) {
                    this.state.order.estimatedMakingTime = this.state.deliveryStartTime;
                    this.props.dbHandler.updateOrderDB(this.state.order, true, false, this.props.onOrderUpdateFinish);
                    this.props.order.state = this.state.order.state;
                    this.props.onClose(true);
                }
                else if (!this.state.orderWasChanged) {
                    this.setState({ timeIsValid: false });
                }
                break;
            case "back":
                this.props.onClose();
                break;
            case "showDebugModal":
                this.setState({ showDebugModal: true });
                break;
            default:
                this.props.onClose();
                break;
        }
    },
    render: function () {
        let buttons;
        if (this.props.onlyShowCart) {
            buttons = <MainButton
                classname="modal-button"
                content="Nazad"
                onClick={this.props.onClose}
            />
        }
        else {
            if (this.state.order.state === enumRequestState.PENDING_FOR_CLIENT) {
                buttons = <>
                    <MainButton
                        classname="modal-button red-button"
                        content="Otkaži"
                        onClick={this.onButtonClick.bind(this, "cancelOrder")}
                    />
                    <div className="processing-time">
                        <p className="single-order-info">Procenjeno vreme pripreme (min):</p>
                        <div className={(this.state.timeIsValid ? "single-info info-width " : "single-info info-width border-red ")}>
                            <input type="number" name="" id="approx-delivery-time" min="0" step="1"
                                value={this.state.deliveryStartTime} onChange={this.onInputChange} />
                        </div>
                    </div>
                    <MainButton
                        classname="modal-button green-button"
                        content="Prihvati"
                        onClick={this.onButtonClick.bind(this, "confirmOrder")}
                    /></>
            }
            else {
                buttons = <MainButton
                    classname="modal-button"
                    content="Nazad"
                    onClick={this.onButtonClick.bind(this, "back")}
                />
            }
        }

        if (this.state.showOrderCancellationComment) {
            return (
                <SingleOrderCancellationModal
                    order={this.state.order}
                    onClick={this.onButtonClick}
                />
            );
        }
        else if (this.state.showDebugModal) {
            return (
                <DebugObjectModal
                    obj={this.state.order}
                    onClick={() => { this.setState({ showDebugModal: false }); }} />
            );
        }
        else {
            return (
                <div className="single-order-cart-modal-container">
                    <div className="single-order-cart-content">
                        <div className="single-order-cart-header">
                            <h1 className="single-order-cart-title">Porudžbina</h1>
                            <MainButton
                                classname="modal-button close-modal"
                                content="&times;"
                                onClick={this.onButtonClick.bind(this, "back")}
                            />
                        </div>
                        <div className="single-order-body cart-order">
                            <div className="order-items">
                                <div className="item-number">
                                    <p>Broj artikala u porudžbini: {this.state.order.cart.items.length}</p>
                                </div>
                                {this.state.order.cart ?
                                    <>
                                        {this.state.order.cart.items.map((item) => {
                                            return SingleOrderItem(item)
                                        })}
                                    </> : null}
                                <div className="item-number">
                                    <p>Kraj porudžbine</p>
                                </div>
                            </div>
                            <div className="single-order-comment">
                                {this.state.orderWasChanged ? <div className="paying-for-order">
                                    <p>Izmene nisu moguće. Korisnik "{this.props.order.getLastStateChangeUser()}" je izmenio stanje zahteva.</p>
                                </div> : null}
                                <h2>Napomena:</h2>
                                <div className={("comment " + (this.state.order.comment.length === 0 ? "" : "border-red"))}>
                                    <p>{this.state.order.comment}</p>
                                </div>
                                <div className="total-order-price">
                                    <h1>Total: {this.state.order.cart.getCurrentPrice()} RSD</h1>
                                </div>
                                {/* <div className="receivers-address">
                                    <p>Adresa za dostavu:</p>
                                    <p>{this.state.order.receiverAddress.name} </p>
                                    <p>{this.state.order.receiverAddress.address} </p>
                                    <p>{this.state.order.receiverAddress.phone} </p>
                                    This is not a client's business
                                </div> */}
                            </div>
                        </div>
                        <div className="single-order-footer">
                            {buttons}
                            {(this.props.user && this.props.user.settings && this.props.user.settings.showDebug) ?
                                <MainButton
                                    classname="modal-button shopping-cart-button"
                                    content={<FontAwesomeIcon icon={faBug} />}
                                    id="showDebugModal"
                                    onClick={this.onButtonClick.bind(this, "showDebugModal")}
                                />
                                : null}
                        </div>
                    </div>
                </div>
            )
        }
    }
});

function SingleOrderItem(item) {
    const subItemsInName = [];
    const subItemsRadioButtons = [];
    const subItemsCheckBox = [];
    let showItemPrice = false;
    Object.values(item.items).forEach(subItem => {
        if (subItem.selected) {
            if (subItem.usePrice && subItem.price > 0) {
                showItemPrice = true;
            }
            else if (item.itemCount > 1) {
                showItemPrice = true;
            }
            if (subItem.useInName) {
                subItemsInName.push(subItem);
            }
            if (subItem.isRadioButton) {
                subItemsRadioButtons.push(subItem);
            }
            else {
                subItemsCheckBox.push(subItem);
            }
        }
    });
    return (
        <div className="order-single-item">
            <div className="single-item-info">
                <div className="food-name">
                    <p>{item.name + "   " + subItemsInName.map((subItem) => {
                        return subItem.name + " ";
                    }).join(' ')}</p>
                    {(item.price && item.price > 0 && showItemPrice) ? <p>{item.price}</p> : null}
                </div>
                {(subItemsRadioButtons && subItemsRadioButtons.length > 0) ?
                    <>
                        <div className="subitems">
                            <p>{subItemsRadioButtons.map((subItem) => {
                                return <div className="subitem-info">
                                    <p>{subItem.name}</p>
                                    <p><strong>{subItem.price && subItem.usePrice ?
                                        <>
                                            {item.price && item.price > 0 ? "+ " : ""}
                                            {Number(subItem.price).toFixed(2)}
                                        </> : null}</strong></p>
                                </div>;
                            })}</p>
                        </div>
                    </> : null}
                {(subItemsCheckBox && subItemsCheckBox.length > 0) ?
                    <>
                        <p className="subitem-title">Dodaci:</p>
                        <div className="subitems">
                            <p>{subItemsCheckBox.map((subItem) => {
                                return <div className="subitem-info">
                                    <p>{subItem.name}</p>
                                    <p><strong>{subItem.price && subItem.usePrice ?
                                        <>
                                            + {Number(subItem.price).toFixed(2)}
                                        </> : null}</strong></p>
                                </div>;
                            })}</p>
                        </div>
                    </> : null}
                <div className="total-price">
                    <p>Cena proizvoda:</p>
                    <p>{item.getCurrentPrice()}</p>
                </div>
            </div>
            <div className="quantity-big">
                X{item.itemCount}
            </div>
        </div>
    )
}

export default SingleOrderCart;