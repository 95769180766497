import React from 'react';
import './App.css';
import StatusBar from './libs/components/StatusBar';
import PortalPage from './libs/components/PortalPage';
import Header from "./libs/components/Header";
import AuthenticationManager from './libs/firebase/authentication';
import enumAdminType from "./libs/model/enums/adminType";
import DBHandler from './libs/firebase/database-handler';
import Loader from './libs/libs-common/Loader';
import MessageBox from './libs/libs-common/MessageBox';
import DeliveryModal from './libs/components/DeliveryModal';
import NotificationManager from './libs/components/NotificationManager';
import AppConnectionState from './libs/libs-common/app-connection-state';
import AppVersionControl from './libs/components/AppVersionControl';
import packageJason from "../package.json";
import { dateToFullString } from './libs/libs-common/DateTimeHelper';
import AdvancedSearch from './libs/components/AdvancedSearch';
import enumRequestState from './libs/model/enums/request_state';
var createReactClass = require('create-react-class');

var App = createReactClass({
  getInitialState: function () {
    const _auth_manager = new AuthenticationManager(this.doRedirectionToHome);
    const _notificationManager = new NotificationManager("magic-marimba", 10000);
    _notificationManager.startAsync();
    _auth_manager.addAuthStateChangeFunction(this.onAuthStateChanged);
    const _dbHandler = new DBHandler();
    return {
      user: null,
      auth_manager: _auth_manager,
      auth_initialized: false,
      register: false,
      message: "",
      redirect: false,
      dbHandler: _dbHandler,
      pendingForClient: false,
      pendingForDelivery: false,
      inProgress: false,
      done: false,
      canceled: false,
      selectedDate: new Date(),
      selectedSort: "unseenOnTopI",
      ordersFromListener: [],
      showNewDeliveryModal: false,
      notificationManager: _notificationManager,
      disableSideMenu: true,
      advancedSearchChange: false,
      client: null,
      deliveries: {},
    };
  },
  onDbChangeEvent: function (orders) {
    this.setState({ ordersFromListener: orders },
      this.state.notificationManager.setNumberOfOrders(this.getUnseenOrdersObj()));
  },
  onAuthStateChanged: function (user) {
    this.state.notificationManager.setUser(user);
    if (user && this.validateUserPermissions(user)) {
      this.state.dbHandler.initializeListeners(user, this.onDbChangeEvent);
      if (user.type === enumAdminType.CLIENT) {
        if (user.storeIds[0]) {
          this.state.dbHandler.getClientById(user.storeIds[0], (client) => { this.setState({ client: client }); })
        }
        this.setState({
          user: user,
          auth_initialized: true,
          pendingForClient: true,
        });
      }
      else {
        this.setState({
          user: user,
          auth_initialized: true,
          pendingForDelivery: true,
        });
      }
      if (user.deliveryIds.length > 0) {
        this.state.dbHandler.getDeliveriesByIds(user.deliveryIds,
          (deliveries) => {
            this.setState({ deliveries: deliveries });
          });
      }
    }
    else {
      if (!AuthenticationManager.getSessionToken()) {
        this.setState({
          user: user,
          auth_initialized: true,
          message: "Morate biti ulogovani da bi vam pristup stranici bio omogućen. Nalog mora sadržati odgovarajuće permisije!",
          redirect: true,
        });
      }

    }
  },
  validateUserPermissions: function (user) {
    return (user.type === enumAdminType.SUPER_ADMIN ||
      user.type === enumAdminType.CLIENT ||
      user.type === enumAdminType.DISPATCHER)
  },
  doRedirectionToHome: function () {
    if (AuthenticationManager.appDomain === "localhost") {
      window.location.href = "http://localhost:3001";
    }
    else {
      window.location.href = "https://internal-portal-de-mango.rs/";
    }
  },
  onStatusSelectionChange: function (statusObj) {
    this.setState(statusObj);
  },
  onNewDeliveryClick: function () {
    // TODO: Disable opening it if user does not have delivery ID.
    this.setState({ showNewDeliveryModal: true });
  },
  enableDisableSideMenu(disable = true) {
    this.setState({ disableSideMenu: disable });
  },
  getUnseenOrdersObj() {
    const retObject = {
      [enumRequestState.PENDING_FOR_CLIENT]: 0,
      [enumRequestState.PENDING_FOR_DELIVERY]: 0,
    };
    this.state.ordersFromListener.forEach(order => {
      if (!order.seen) {
        switch (order.state) {
          case enumRequestState.PENDING_FOR_CLIENT:
            retObject[enumRequestState.PENDING_FOR_CLIENT]++;
            break;
          case enumRequestState.PENDING_FOR_DELIVERY:
            retObject[enumRequestState.PENDING_FOR_DELIVERY]++;
            break;

          default:
            break;
        }
      }
    });

    return retObject;
  },
  render: function () {
    let content;
    if (!this.state.auth_initialized) {
      content = <><Loader /></>;
    }
    else if (this.state.redirect) {
      content =
        <>
          <MessageBox
            title="Upozorenje!"
            content={this.state.message}
            onClick={this.doRedirectionToHome}
          />;
        </>
    }
    else {
      content =
        <div className="app-row-order">
          {this.state.advancedSearch ?
            <AdvancedSearch
              user={this.state.user}
              onSelectionChange={this.onStatusSelectionChange}
              pendingForDelivery={this.state.pendingForDelivery}
              pendingForClient={this.state.pendingForClient}
              selectedDate={this.state.selectedDate}
              unreadObj={this.getUnseenOrdersObj()}
              onNewOrderClick={this.onNewDeliveryClick}
              disabled={this.state.disableSideMenu}
              advancedSearchChange={() => { this.setState({ advancedSearch: false, }); }} /> :
            <StatusBar
              user={this.state.user}
              onSelectionChange={this.onStatusSelectionChange}
              pendingForDelivery={this.state.pendingForDelivery}
              pendingForClient={this.state.pendingForClient}
              selectedDate={this.state.selectedDate}
              unreadObj={this.getUnseenOrdersObj()}
              onNewOrderClick={this.onNewDeliveryClick}
              disabled={this.state.disableSideMenu}
              advancedSearchChange={() => { this.setState({ advancedSearch: true, }); }} />
          }
          <PortalPage dbHandler={this.state.dbHandler}
            user={this.state.user}
            ordersFromListener={this.state.ordersFromListener}
            enableDisableSideMenu={this.enableDisableSideMenu}
            deliveries={this.state.deliveries}
            selectedOrderStatus={
              {
                pendingForClient: this.state.pendingForClient,
                pendingForDelivery: this.state.pendingForDelivery,
                inProgress: this.state.inProgress,
                done: this.state.done,
                canceled: this.state.canceled,
                selectedDate: this.state.selectedDate,
                selectedSort: this.state.selectedSort,
              }} />
        </div>;
    }
    return (
      <div className="app-container">
        <AppVersionControl dbHandler={this.state.dbHandler} currentVersion={packageJason.buildDate} />
        <AppConnectionState />
        <div className="portal-page-container">
          <div className="portal-page-content">
            <Header user={this.state.user}
              onLogOut={this.state.auth_manager.logOut.bind(this, this.doRedirectionToHome)}
              notificationManager={this.state.notificationManager} />
            {this.state.showNewDeliveryModal ?
              <>
                <DeliveryModal
                  onClose={() => { this.setState({ showNewDeliveryModal: false }) }}
                  dbHandler={this.state.dbHandler}
                  user={this.state.user}
                  client={this.state.client}
                />
              </> : null}
            {content}
          </div>
        </div>
        {/* TODO: Show build date on the left bottom part of the page. */}
        {(this.state.user && this.state.user.type === enumAdminType.SUPER_ADMIN) ?
          <p className="build-date">Build Date: <br /> {dateToFullString(packageJason.buildDate)} </p> : null}
      </div>
    );
  }
});

export default App;
