import Image from "./image";

export class Delivery {
    id: string;
    priority: number;
    deliveryStartPrice: number;
    deliveryInfoImg: Image;
    backupDelivery: string;
    name: string

    constructor() {
        this.id = "";
        this.priority = 0;
        this.deliveryStartPrice = 0;
        this.deliveryInfoImg = new Image();
        this.backupDelivery = "";
        this.name = "";
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.priority) {
                this.priority = jsonObject.priority;
            }
            if (jsonObject.deliveryStartPrice) {
                this.deliveryStartPrice = jsonObject.deliveryStartPrice;
            }
            if (jsonObject.deliveryInfoImg) {
                this.deliveryInfoImg.loadFromJson(jsonObject.deliveryInfoImg);
            }
            if (jsonObject.backupDelivery) {
                this.backupDelivery = jsonObject.backupDelivery;
            }
            if (jsonObject.name) {
                this.name = jsonObject.name;
            }
        }
    }

    toJason(): any {
        return {
            id: this.id,
            priority: this.priority,
            deliveryStartPrice: this.deliveryStartPrice,
            deliveryInfoImg: this.deliveryInfoImg,
            backupDelivery: this.backupDelivery,
            name: this.name,
        };
    }
}

export default Delivery;
