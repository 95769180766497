import React from 'react';
import '../../style/NotificationManagerWrapper.css';
import enumAdminType from '../model/enums/adminType';
var createReactClass = require('create-react-class');

var NotificationManagerWrapper = createReactClass({
    getInitialState: function () {
        this.props.notificationManager.assignPlayFunction(this.playAudio);
        return {
            notificationManager: this.props.notificationManager,
            audioPath: "",
            muted: false,
            showMuteButton: this.props.user ?
                (this.props.user.type === enumAdminType.SUPER_ADMIN ||
                    this.props.user.settings.showDebug) : false,
        };
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                showMuteButton: this.props.user ?
                    (this.props.user.type === enumAdminType.SUPER_ADMIN ||
                        this.props.user.settings.showDebug) : false,
            });
        }
    },
    playAudio: function (audioPath) {
        this.setState({ audioPath: audioPath }, () => {
            if (!this.state.muted) {
                const audioEl = document.getElementsByClassName("audio-element")[0]
                if (audioEl) {
                    audioEl.load();
                    const playPromise = audioEl.play();
                    if (playPromise) {
                        playPromise.then(() => {
                            // console.log("Fine");
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }
            }
        });
    },
    render: function () {
        return (
            <>
                <audio className="audio-element">
                    <source src={("../audio/" + this.state.audioPath)}></source>
                    Your browser does not support the audio element.
                </audio>
                {this.state.showMuteButton ?
                    <button on onClick={() => {
                        this.setState({ muted: !this.state.muted });
                        // this.playAudio("mixkit_bell_notification_933.wav");
                    }}
                        className={(this.state.muted ? "mute-sound" : "unmute-sound")}>
                        <img src={(this.state.muted ? "./images/mute1.svg" : "./images/volume1.svg")} alt="" />
                    </button>
                    : null}
            </>
        );
    }
});

export default NotificationManagerWrapper;
