import Admin from '../model/admin';
import Order from '../model/order';
import { getDayIntervalFromDate } from '../libs-common/DateTimeHelper.js';
import firebase from './firebase.js';
import { db } from './firebase.js';
import enumAdminType from '../model/enums/adminType';
import Client from '../model/client';
import Delivery from '../model/delivery';

class DBHandler {

    constructor() {
        this.initialized = false;
    }

    initializeVersionListener(onChangeFunction) {
        const refVersion = db.collection("appVersions").doc("deMangoAdminDelivery");;
        refVersion.onSnapshot((querySnapshot) => {
            const retObj = querySnapshot.data();
            let version = 0;
            if (retObj && retObj.version) {
                version = retObj.version
            }
            if (onChangeFunction) {
                onChangeFunction(version);
            }
        });
    }

    initializeListeners(user, onChangeFunction) {
        let refEvents = [];
        if (user.type === enumAdminType.CLIENT) {
            if (user.storeIds && user.storeIds.length > 0) {
                user.storeIds.forEach(storeId => {
                    const refEvent = db.collection("clients").doc(storeId).collection("events");
                    refEvents.push(refEvent);
                });
            }
        }
        else if (user.type !== enumAdminType.SUPER_ADMIN) {
            if (user.deliveryIds && user.deliveryIds.length > 0) {
                user.deliveryIds.forEach(deliveryId => {
                    const refEvent = db.collection("delivery").doc(deliveryId).collection("events");
                    refEvents.push(refEvent);
                });
            }
        }
        else {
            if (user.storeIds && user.storeIds.length > 0) {
                user.storeIds.forEach(storeId => {
                    const refEvent = db.collection("clients").doc(storeId).collection("events");
                    refEvents.push(refEvent);
                });
            }
            if (user.deliveryIds && user.deliveryIds.length > 0) {
                user.deliveryIds.forEach(deliveryId => {
                    const refEvent = db.collection("delivery").doc(deliveryId).collection("events");
                    refEvents.push(refEvent);
                });
            }
        }
        if (refEvents.length > 0) {
            refEvents.forEach(refEvent => {
                refEvent.onSnapshot((querySnapshot) => {
                    const orders = [];
                    querySnapshot.forEach((order) => {
                        const orderObj = new Order();
                        orderObj.loadFromJson(order.data());
                        orderObj.loadFromJson(order.id);
                        if (!orderObj.isEmpty()) {
                            orders.push(orderObj);
                        }
                    });
                    onChangeFunction(orders);
                });
            });
            this.initialized = true;
        }
    }

    //#region  Orders DB functions

    async addOrderDB(order, onDbFinish, clientId = "") {
        const addOrderFunc = firebase.functions().httpsCallable('addOrderToDB');
        try {
            const result = await addOrderFunc({ order: order.toJason(), clientId: clientId });
            if (result.data.error) {
                console.log(result.data.error);
                if (onDbFinish) {
                    onDbFinish(false);
                }
            }
            else {
                if (onDbFinish) {
                    onDbFinish(true);
                }
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(false);
            }
        }
    }

    async updateOrderDB(order, updateOrderState, cancelOrder, onDbFinish) {
        const addOrderFunc = firebase.functions().httpsCallable('updateOrderToDB');
        try {
            const result = await addOrderFunc({
                order: order.toJason(), changeState: updateOrderState, cancel: cancelOrder,
            });
            if (result.data.error) {
                console.log(result.data.error);
            }
            let orderObj = null;
            if (result.data.order) {
                orderObj = new Order();
                orderObj.loadFromJson(result.data.order);
            }
            if (onDbFinish) {
                onDbFinish(orderObj ? orderObj : order);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(order);
            }
        }
    }

    async clearOrderEvent(order, onDbFinish) {
        const addOrderFunc = firebase.functions().httpsCallable('seenOrderEvent');
        addOrderFunc({ order: order.toJason() })
            .then((result) => {
                if (result.error) {
                    console.log(result);
                }
                if (onDbFinish) {
                    onDbFinish();
                }
            })
            .catch((result) => {
                console.log(result);
                if (onDbFinish) {
                    onDbFinish(result);
                }
            });
    }

    async getOrderByIdDB(orderId, onDbFinish) {
        const getOrderByIdFunc = firebase.functions().httpsCallable('getOrderById');
        getOrderByIdFunc({ orderId: orderId })
            .then((result) => {
                if (result.error) {
                    console.log(result);
                }
                if (onDbFinish) {
                    onDbFinish(result);
                }
            })
            .catch((result) => {
                console.log(result);
                if (onDbFinish) {
                    onDbFinish(result);
                }
            });
    }

    async getOrdersByIdsDB(orderIds, sequence, useSequence, onDbFinish) {
        const getOrderByIdsFunc = firebase.functions().httpsCallable('getOrdersByIds');
        getOrderByIdsFunc({ orderIds: orderIds, sequence: sequence, useSequence: useSequence })
            .then((result) => {
                const ordersObj = [];
                if (result.error) {
                    console.log(result);
                }
                else if (result.data && result.data.orders && result.data.orders.length > 0) {
                    result.data.orders.forEach(orderJson => {
                        let tempOrderObj = new Order();
                        tempOrderObj.loadFromJson(orderJson);
                        ordersObj.push(tempOrderObj);
                    });
                }
                else {
                    console.log("There is no Data in order DB respond!");
                }

                if (onDbFinish) {
                    onDbFinish(ordersObj);
                }
            })
            .catch((result) => {
                console.log(result);
                if (onDbFinish) {
                    onDbFinish(result);
                }
            });
    }

    async getOrdersByStatesDB(orderStates, selectedDate, onDbFinish) {
        if (!orderStates) {
            console.log("States collection must have at least one element!");
            return [];
        }
        const dateInterval = getDayIntervalFromDate(selectedDate);
        const getOrdersByStatesFunc = firebase.functions().httpsCallable('getOrdersByStates');
        getOrdersByStatesFunc({ orderStates: orderStates, startDate: dateInterval.startDate, endDate: dateInterval.endDate })
            .then((result) => {
                const ordersObj = [];
                if (result.error) {
                    console.log(result);
                }
                else if (result.data && result.data.orders && result.data.orders.length > 0) {
                    result.data.orders.forEach(orderJson => {
                        let tempOrderObj = new Order();
                        tempOrderObj.loadFromJson(orderJson);
                        ordersObj.push(tempOrderObj);
                    });
                }
                else {
                    console.log("There is no Data in order DB respond!");
                }

                if (onDbFinish) {
                    onDbFinish(ordersObj);
                }
            })
            .catch((result) => {
                console.log(result);
                if (onDbFinish) {
                    onDbFinish([]);
                }
            });
    }

    //#endregion  Orders DB functions

    async getClientById(clientID, onDbFinish) {
        const refClientDoc = firebase.firestore().collection("clients").doc(clientID);
        let clientObj = null;
        try {
            const querySnapshot = await refClientDoc.get();
            const result = querySnapshot.data();
            if (result.id) {
                clientObj = new Client();
                clientObj.loadFromJson(result);
            }
            else {
                console.log("Client object from DB is empty.");
            }
            if (onDbFinish) {
                onDbFinish(clientObj);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(clientObj);
            }
        }
    }

    async getAdminByEmailDB(admin_email) {
        let admin = null;
        const refAdmin = firebase.firestore().collection("/admins").doc(admin_email);
        await refAdmin.get()
            .then(function (docRef) {
                admin = new Admin();
                admin.loadFromJson(docRef.data());
            })
            .catch(function (error) {
                console.error("Error adding admin: ", error);
                return false;
            });

        return admin;
    }

    async getDeliveriesByIds(ids, onDbFinish) {
        const deliveries = {};
        const refDelivery = firebase.firestore().collection("/delivery");
        try {
            const dbResult = await refDelivery.get();
            dbResult.forEach(deliveryDoc => {
                if (ids.includes(deliveryDoc.id)) {
                    const tempDelivery = new Delivery();
                    tempDelivery.loadFromJson(deliveryDoc);
                    tempDelivery.loadFromJson(deliveryDoc.data());
                    deliveries[tempDelivery.id] = tempDelivery;
                }
            });
        }
        catch (ex) {
            console.error("Error getting deliveries: ", ex);
        }

        if (onDbFinish) {
            onDbFinish(deliveries);
        }
        return deliveries;
    }

    async getCustomToken(onDbFinish) {
        const getCustomTokenFunc = firebase.functions().httpsCallable('getCustomToken');
        getCustomTokenFunc()
            .then((result) => {
                if (result.data.token) {
                    if (onDbFinish) {
                        onDbFinish(result.data.token);
                    }
                }
                else if (result.data.error) {
                    console.log(result.data.error);
                    if (onDbFinish) {
                        onDbFinish(null);
                    }
                }
                else {
                    console.log("Token and error are null!");
                    if (onDbFinish) {
                        onDbFinish(null);
                    }
                }
            })
            .catch((result) => {
                console.log("Error getting Custom Session Token!");
                console.log(result);
                if (onDbFinish) {
                    onDbFinish(null);
                }
            });
    }
}

export default DBHandler;