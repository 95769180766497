import createReactClass from 'create-react-class';
import React from 'react';
import "../../style/DisplayCard.css";
import { timeToHoursAndMinutes, timeToDate } from '../libs-common/DateTimeHelper';
import enumRequestState from '../model/enums/request_state';
import Loader from '../libs-common/Loader';
import enumAdminType from '../model/enums/adminType';

var DisplayCard = createReactClass({
    getInitialState: function () {
        return this.getStateObjByOrderState(this.props.order.state);
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.order !== prevProps.order) {
            this.setState(this.getStateObjByOrderState(this.props.order.state));
        }
        if (this.props.order.seen !== this.state.seen) {
            this.setState({ seen: this.props.order.seen });
        }
        if (this.props.order.updating !== this.state.updating) {
            this.setState({ updating: this.props.order.updating });
            this.setState(this.getStateObjByOrderState(this.props.order.state));
        }
    },
    getStateObjByOrderState: function (orderState) {
        let retObj = {};
        switch (orderState) {
            case enumRequestState.PENDING_FOR_CLIENT:
                retObj = {
                    img: "./images/shoe-shop.svg",
                    cardClass: "pending-for-client",
                    infoClass: "pending-for-client-info",
                    status: "Dospelo lokalu",
                };
                break;
            case enumRequestState.PENDING_FOR_DELIVERY:
                retObj = {
                    img: "./images/loading.svg",
                    cardClass: "pending-for-delivery",
                    infoClass: "pending-for-delivery-info",
                    status: "Dospela dostava",
                };
                break;
            case enumRequestState.IN_PROGRESS:
                retObj = {
                    img: "./images/express-delivery.svg",
                    cardClass: "in-progress",
                    infoClass: "in-progress-info",
                    status: "Zahtev u obradi",
                };
                break;
            case enumRequestState.DONE:
                retObj = {
                    img: "./images/checkmark.svg",
                    cardClass: "done",
                    infoClass: "done-info",
                    status: "Završen zahtev",
                };
                break;
            case enumRequestState.CANCELED:
                retObj = {
                    img: "./images/cancel.svg",
                    cardClass: "canceled",
                    infoClass: "canceled-info",
                    status: "Otkazan zahtev",
                };
                break;
            default:
                break;
        }
        retObj.seen = this.props.order.seen;
        return retObj;
    },
    render: function () {
        return (
            <div className="display-card-container">
                {(this.props.user.type !== enumAdminType.CLIENT &&
                    this.props.order &&
                    this.props.order.cart &&
                    this.props.order.cart.client &&
                    this.props.order.cart.client.informMeByPhone) ?
                    <>
                        <div className="call-store-for-order">
                            <img src="./images/call.svg" alt="" />
                        </div>
                    </> : <></>}
                <div className="dispaly-card" onClick={() => { this.props.onClick(this.props.order) }}>
                    {this.props.order.updating ? Loader() : null}
                    <div className={!this.state.seen ? "display-card-status " + this.state.cardClass + " new-order" : "display-card-status " + this.state.cardClass}>
                        <img src={this.state.img} alt="" />
                        <p className="delivery-status">{this.state.status}</p>
                    </div>
                    <div className={"display-card-info " + this.state.infoClass}>
                        <p className="info-title">Primalac</p>
                        <p className="client-info">{this.props.order.receiverAddress.name} <br /> {this.props.order.receiverAddress.address}</p>
                        <p className="info-title">Pošiljalac</p>
                        <p className="client-info">{this.props.order.senderAddress.name} <br /> {this.props.order.senderAddress.address}</p>
                        <p className="info-title">{timeToHoursAndMinutes(this.props.order.dateOfCreation)}</p>
                        <p className="client-info">{timeToDate(this.props.order.dateOfCreation)}</p>
                        {/* TODO: Move this to the place where it should be  */}
                        {Object.keys(this.props.deliveries).length > 1 ?
                            <p className="client-info">
                                {this.props.deliveries[this.props.order.deliveryId].name}
                            </p> : null}
                    </div>
                </div>

            </div>
        )
    }

});

export default DisplayCard
