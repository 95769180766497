import '../../style/Header.css';
import createReactClass from 'create-react-class';
import NotificationManagerWrapper from './NotificationManagerWrapper';
import MainButton from '../libs-common/MainButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

var Header = createReactClass({
    render: function () {
        return (
            <div className="portal-header">
                <div className="header-container">
                    <div className="header-content">
                        <input type="checkbox" id="portal-open" />
                        <label htmlFor="portal-open" className="portal-open-aside">
                            <i><FontAwesomeIcon icon={faBars} /></i>
                        </label>
                        <nav className="navbar">
                            <NotificationManagerWrapper
                                notificationManager={this.props.notificationManager}
                                user={this.props.user} />
                            <div className="portal-page-title">
                                <img className="type-icon" src="./images/delivery-man.svg" alt="" />
                                <p className="title">Dostava</p>
                            </div>
                            <div className="logged-in-user">
                                {this.props.user ?
                                    <>
                                        <p className="user-email">{this.props.user.email}</p>
                                        <MainButton
                                            onClick={this.props.onLogOut}
                                            classname="log-button"
                                            content="Izloguj se"
                                        />
                                    </> : <> </>}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
});

export default Header